import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import DeliverySectionLayout from '../../Components/DeliverySectionLayout/DeliverSectionLayout';
import './styles.css'
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';
import { Button } from '@mui/base';
import SmartFeatures from '../../Components/SmartFeatures';
import SignUpForm from '../../Components/SignUpForm';
import CustomerCommunication from '../../Components/CustomerCommunication';
import { Link } from 'react-router-dom';

const mockData = {
    possibleImageCards: [
        {
            industryType: "eCommerce",
            imgContent:
                "/assets/img_73_64_d65bb1.svg",
        },
        {
            industryType: "QCommerce",
            imgContent:
                "/assets/img_73_67_eec6ca.svg",
        },
        {
            industryType: "D2C",
            imgContent:
                "/assets/img_73_70_192a70.svg",
        },
        {
            industryType: "3PLs",
            imgContent:
                "/assets/img_73_73_e92f31.svg",
        },
        {
            industryType: "FMCG & Retail",
            imgContent:
                "/assets/img_73_76_b933f2.svg",
        },
        {
            industryType: "Commodities",
            imgContent:
                "/assets/img_73_79_8938b3.svg",
        },
    ],
    possiblePageTitles: [{ pageTitle: "WhistleOS for Mobility" }, { pageTitle: "Executive Car Rentals" }, { pageTitle: "WhistleOS for Transportation" }, { pageTitle: "Whistle Electric" }],
};

const osAdvantages = [
    {
        id: 1,
        image: 'low-code',
        title: 'Low-Code Integrations'
    },
    {
        id: 2,
        image: 'third-party',
        title: 'Third-Party Connectors'
    },
    {
        id: 3,
        image: 'costs',
        title: 'No Upfront Costs'
    },
    {
        id: 4,
        image: 'time',
        title: 'Quick Time to '
    },
    {
        id: 5,
        image: 'future',
        title: 'Future Ready Platform'
    },
    {
        id: 6,
        image: 'approach',
        title: 'Consultative Approach'
    },
]

const SmartFeaturesData = [
    {
        id: 1,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "WhistleOS Hub Ops App",
        desc: "Modern way to manage all hub operations through a simple mobile app.",
        img: 'middleicon1'

    },
    {
        id: 2,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Mid Mile Bag Creation",
        desc: "Ensure middle mile bag creation and consolidation through system guided operations and missed shipment alerts.",
        img: 'middleicon2'

    },
    {
        id: 3,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Intelligent Scanning",
        desc: "Trip creation with scan-based loading and unloading of shipments/bags.",
        img: 'middleicon3'

    },
    {
        id: 4,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Smart Trip Creation",
        desc: "Create mid mile trips across air and road with the ability to capture flight/vehicle details along with connection times.",
        img: 'middleicon4'


    },
]

function MiddleMile() {
    return (
        <div className='main-container'>
            <Navbar />
            {/* <DeliverySectionLayout possiblePageTitles={mockData?.possiblePageTitles} /> */}
            <div className="delivery-experience-section4">
                {/* <DeliveryExperience /> */}
                <div className="delivery-experience-section6">
                    <div className="delivery-section">
                        <div className="delivery-info">
                            <p className="enhanced-customer-experience-heading">
                            Transform Mid-Mile 
                                <br />
                                Logistics  with Cost-Effective Precision 
                            </p>

                            <p className="enhanced-customer-experience">
                            Embrace a new era of logistics efficiency with WhistleOS. Our platform revolutionizes mid-mile operations, cutting costs without compromising on the quality of service. Ensure your hub-to-hub transfers are performed flawlessly, with each package routed through the most cost-effective channels. Streamline your operations today for a more profitable tomorrow. 
                            </p>
                            <Link to='/LetsTalk'>
                                <Button className="enhanced-customer-experience-button">Request a demo</Button>

                            </Link>

                        </div>
                        <div className="delivery-image">
                            <img
                                src="./assets/middlemile1.png"
                                className="image-container5"
                            />
                        </div>
                    </div>
                    {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

                </div>
                <div className="delivery-operations">
                    <p className="delivery-heading">
                    Total Shipment Transparency with 
                        <br />
                        Our One-Stop Platform 

                        {/* Visibility Into Shipment Movements */}
                    </p>
                    <SmartFeatures SmartFeaturesData={SmartFeaturesData} />
                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-container">
                            <p className="app-title">
                                Enhance Hub Efficiency with Advanced

                                <br />
                                Sorting and Consolidation
                            </p>
                            <ol className="driver-info-list1">
                                <li>Strategically allocate goods to the correct channels, reducing handling times. </li>
                                <li>Combine shipments in the most efficient manner, ensuring vehicles leave full and ready. </li>
                                <li>
                                    Protect your cargo from the moment it arrives until its final departure, significantly reducing the risk of damage. </li>


                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/middlemile2.png"
                                className="image-container13"
                            />
                        </div>

                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/middlemile3.png"
                                className="image-container13"
                            />
                        </div>
                        <div className="driver-info-container">
                            <p className="app-title">
                                Power Up Your Hub

                                <br />
                                Operations with Mobile Technology
                            </p>
                            <ol className="driver-info-list1">
                                <li>Instant access to real-time data, allowing for swift, informed decisions on the move. </li>
                                <li>Enhanced coordination between different hubs, ensuring a smooth flow of goods. </li>
                                <li>Proactive problem-solving with immediate updates and alerts, reducing downtime and enhancing customer satisfaction. </li>

                            </ol>
                        </div>


                    </div>

                </div>

                <div className="driver-info-section" style={{paddingBottom:'5%'}}>
                    <div className="driver-info-main-container">

                        <div className="driver-info-container">
                            <p className="app-title">
                                Strategize and Secure

                                <br />

                                Your Shipments with Finesse
                            </p>
                            <ol className="driver-info-list1">
                                <li>Deploy dynamic route planning to dodge potential delays, always staying one step ahead. </li>
                                <li>Implement robust handling instructions tailored to each package, minimizing the risk of damage during transit. </li>
                                <li>Utilize cutting-edge tracking technology to monitor the journey of each consignment, reassuring your customers and reinforcing trust. </li>
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/middlemile4.png"
                                className="image-container13"
                            />
                        </div>


                    </div>

                </div>
                <SignUpForm />



            </div>
            <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />
        </div>
    )
}

export default MiddleMile;
