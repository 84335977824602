import PageContent from "../PageContent/PageContent";
import "./style.css";

function WebsiteLayout({ possiblePageTitles }:any) {
  return (
    <div className="main-container2">
      <img
        src="/assets/img_193_160_eb98ed.png"
        className="hero-image"
      />
      <PageContent possiblePageTitles={possiblePageTitles} />
    </div>
  );
}

export default WebsiteLayout;
