import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Landing from './Screens/Landing/Landing';
import OnTime from './Screens/On-Time/On-Time';
import RouteOptimization from './Screens/RouteOptimization/RouteOptimization';
import FirstMile from './Screens/FirstMile/FirstMile';
import MiddleMile from './Screens/MiddleMile/MiddleMile';
import LastMile from './Screens/LastMile/LastMile';
import LetsTalk from './Screens/LetsTalk/LetsTalk';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/last-mile' element={<OnTime />} />
        <Route path='/platform' element={<RouteOptimization />} />
        <Route path='/first-mile' element={<FirstMile />} />
        <Route path='/mid-mile' element={<MiddleMile />} />
        <Route path='/on-demand-delivery-management' element={<LastMile />} />
        <Route path='/LetsTalk' element={<LetsTalk />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
