import { useRef, useState } from "react";
import SvgIcon1 from "./icons/SvgIcon1";
import "./Navbar.css";
import { Button } from "@mui/base";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Modal } from "@mui/base";
import { setTimeout } from "timers/promises";
import Bounce from "react-reveal/Bounce";
// import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Navbar() {
    const [dropDown, setDropDown] = useState<any>(null);
    const [active, setActive] = useState<any>(false)
    const location = useLocation();
    const routeName = location.pathname;
    const [navigation, setNavigation] = useState<any>(false)
    // const sectionRef = useRef<HTMLDivElement>(null);

    // const scrollToSection = () => {
    //     sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    // };
    const navigate = useNavigate();


    const handleContactUsClick = () => {
        const contactUsElement = document.getElementById('contact-us');

        if (contactUsElement) {
            // Scroll to the Contact Us section within the same page
            contactUsElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } else {
            // Navigate to the homepage with a hash to the Contact Us section
            // navigate('/'); // Navigates to the root route
            // handleContactUsClick()
            window.location.href = '/'

            // setTimeout(()=>{
            //     handleContactUsClick()
            // },2000)
        }
    };



    return (
        <div className="header-container">
            <div className="main-navigation">
                <div className="platform-section">
                    <Link to="/">
                        <p className="platform-title1">
                            <span className="whistle-os-heading">Whistle</span>
                            <span className="whistle-os-title1">OS</span>
                        </p>
                    </Link>

                </div>
                <div className="navigation-bar">
                    {/* <SvgIcon1 className="svg-container" /> */}
                    <div className="platform-products1">
                        <Link to="/platform">
                            {/* <p className="platform-title">Platform</p> */}
                            <p className={routeName == "/platform" ? "active-status" : "platform-title"}>Platform</p>
                        </Link>

                        <div onClick={() => setDropDown(!dropDown)} className="drop-downcontainer">
                            {/* <p className="platform-title">Products</p> */}
                            <p className={routeName == "/first-mile" || routeName == "/mid-mile" || routeName == "/last-mile" ? "active-status" : "platform-title"}>Products</p>
                            <img src={routeName == "/first-mile" || routeName == "/mid-mile" || routeName == "/last-mile" ? "./assets/arrow-blue.png" : "./assets/arrow-blac.png"} />
                            {dropDown && (
                                <div className="dropdown" style={{ width: '13%' }}>
                                    <Link to="/first-mile">First Mile</Link>
                                    <Link to="/mid-mile" >Mid Mile</Link>
                                    <Link to="/last-mile" >Last Mile</Link>

                                </div>

                            )}


                        </div>
                        <Link to='/on-demand-delivery-management'>
                            <p className={routeName == "/on-demand-delivery-management" ? "active-status" : "platform-title"}>On Demand Delivery</p>

                        </Link>






                    </div>
                    <Link to="/LetsTalk" className="primary-button-talk">Let’s talk</Link>


                </div>
                <Modal open={active}>
                    <div className={`mobilenavItems ${active ? "active" : ''}`}>
                        <Bounce>


                            <Link to="/platform">
                                {/* <p className="platform-title">Platform</p> */}
                                <p className={routeName == "/platform" ? "active-status" : "platform-title"}>Platform</p>
                            </Link>

                            <div onClick={() => setDropDown(!dropDown)} className="mobile-drop-downcontainer">
                                {/* <p className="platform-title">Products</p> */}
                                <div style={{display:'flex'}}>
                                    <p className={routeName == "/first-mile" || routeName == "/mid-mile" || routeName == "/last-mile" ? "active-status" : "platform-title"}>Products</p>
                                    <img src={routeName == "/first-mile" || routeName == "/mid-mile" || routeName == "/last-mile" ? "./assets/arrow-blue.png" : "./assets/arrow-blac.png"} />

                                </div>

                                {dropDown && (
                                    <div className="mobile-dropdown" style={{ width: '13%' }}>
                                        <Link to="/first-mile">First Mile</Link>
                                        <Link to="/mid-mile" >Mid Mile</Link>
                                        <Link to="/last-mile" >Last Mile</Link>

                                    </div>

                                )}


                            </div>
                            <Link to='/on-demand-delivery-management'>
                                <p className={routeName == "/on-demand-delivery-management" ? "active-status" : "platform-title"}>On Demand Delivery</p>

                            </Link>
                            <Link to="/LetsTalk" className="primary-button-talk">Let’s talk</Link>
                        </Bounce>



                    </div>

                </Modal>

                <div className={`hamburger ${active ? "active" : ''}`} onClick={() => setActive(!active)}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>

            </div>

            {/* <Link to="/LetsTalk" className="primary-button-talk">Let’s talk</Link> */}

        </div>
    );
}

export default Navbar;
