import "./style.css";

function DispatchPlatform() {
  return (
    <div className="dispatch-management-platform">
      <p className="dispatch-management">
        <span className="dispatch-title">Whistle OS </span>
        <span className="dispatch-management-text">Dispatch Management Platform diverse modules leverage powerful algorithms to make each delivery better than the last.</span>
      </p>
      <p className="dispatch-platform-description">
        Right from planning to execution, the platform acts as a single source of truth. Its modules constantly communicate with each for seamless decision-making, better resource utilization, and
        sustainable growth. Enterprises, whether they are retail brands, 3PLs, or e-commerce companies, can scale with new functionalities in no time across multiple fulfillment channels and models.
      </p>
    </div>
  );
}

export default DispatchPlatform;
