import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import DeliverySectionLayout from '../../Components/DeliverySectionLayout/DeliverSectionLayout';
import './styles.css'
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';
import { Button } from '@mui/base';
import SmartFeatures from '../../Components/SmartFeatures';
import SignUpForm from '../../Components/SignUpForm';
import CustomerCommunication from '../../Components/CustomerCommunication';
import { Link } from 'react-router-dom';

const mockData = {
    possibleImageCards: [
        {
            industryType: "eCommerce",
            imgContent:
                "/assets/img_73_64_d65bb1.svg",
        },
        {
            industryType: "QCommerce",
            imgContent:
                "/assets/img_73_67_eec6ca.svg",
        },
        {
            industryType: "D2C",
            imgContent:
                "/assets/img_73_70_192a70.svg",
        },
        {
            industryType: "3PLs",
            imgContent:
                "/assets/img_73_73_e92f31.svg",
        },
        {
            industryType: "FMCG & Retail",
            imgContent:
                "/assets/img_73_76_b933f2.svg",
        },
        {
            industryType: "Commodities",
            imgContent:
                "/assets/img_73_79_8938b3.svg",
        },
    ],
    possiblePageTitles: [{ pageTitle: "WhistleOS for Mobility" }, { pageTitle: "Executive Car Rentals" }, { pageTitle: "WhistleOS for Transportation" }, { pageTitle: "Whistle Electric" }],
};

const osAdvantages = [
    {
        id: 1,
        image: 'low-code',
        title: 'Low-Code Integrations'
    },
    {
        id: 2,
        image: 'third-party',
        title: 'Third-Party Connectors'
    },
    {
        id: 3,
        image: 'costs',
        title: 'No Upfront Costs'
    },
    {
        id: 4,
        image: 'time',
        title: 'Quick Time to '
    },
    {
        id: 5,
        image: 'future',
        title: 'Future Ready Platform'
    },
    {
        id: 6,
        image: 'approach',
        title: 'Consultative Approach'
    },


]



const SmartFeaturesData = [
    {
        id: 1,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Seamless Integrations",
        desc: "Order details can be captured from POS/WMS/OMS/ERP",
        img: 'ondemandicon1'

    },
    {
        id: 2,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Intelligent Order Allocation",
        desc: "Auto allocation engine with dynamic order clubbing and geocoding capabilities",
        img: 'ondemandicon2'

    },
    {
        id: 3,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Driver Management",
        desc: "Fully loaded driver app to manage on demand deliveries",
        img: 'ondemandicon3'

    },
    {
        id: 4,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Customer Experience",
        desc: "Unified, real-time customer communication - SMS/WhatsApp/Email",
        img: 'ondemandicon4'


    },
]

function LastMile() {
    return (
        <div className='main-container'>
            <Navbar />
            {/* <DeliverySectionLayout possiblePageTitles={mockData?.possiblePageTitles} /> */}
            <div className="delivery-experience-section4">
                {/* <DeliveryExperience /> */}
                <div className="delivery-experience-section6">
                    <div className="delivery-section">
                        <div className="delivery-info">
                            <p className="enhanced-customer-experience-heading">
                                Seamless On Demand Delivery
                                <br />
                                Management Platform
                            </p>

                            <p className="enhanced-customer-experience">
                                Ensure SLA adherence, boost customer experience and drive cost-efficient, scalable, and quick deliveries
                            </p>
                            <Link to="/LetsTalk">
                                <Button className="enhanced-customer-experience-button">Request a demo</Button>

                            </Link>

                        </div>
                        <div className="delivery-image">
                            <img
                                src="./assets/lastmile1.png"
                                className="image-container5"
                            />
                        </div>
                    </div>
                    {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

                </div>
                <div className="delivery-operations">
                    <p className="delivery-heading">
                        On Demand Deliveries for Delightful
                        <br />

                        Customer Experiences
                    </p>
                    <SmartFeatures SmartFeaturesData={SmartFeaturesData} />
                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-container">
                            <p className="app-title">
                                Vehicle Routing and

                                <br />
                                Scheduling
                            </p>
                            <ul className="driver-info-list1">
                                <li>Scale your on demand deliveries by deploying AI/ML capabilities</li>
                                <li>Auto allocate orders to drivers based on multiple configurable parameters</li>
                                <li>Ensure SLA adherence and boost route productivity using dynamic order clubbing</li>
                                <li>Avoid misroutes and delays with smart geocoding engine,</li>

                            </ul>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile2.png"
                                className="image-container13"
                            />
                        </div>

                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile3.png"
                                className="image-container13"
                            />
                        </div>
                        <div className="driver-info-container">
                            <p className="app-title">
                                Driver Management
                            </p>
                            <ol className="driver-info-list1">
                                <li>Ensure peak hour availability by managing driver shifts using smart rosters for full/part-time drivers</li>
                                <li>Enhance driver experience using a driver app that helps manage customer preferences, COD remittance, list of pending tasks, and digitizes delivery proofsEnsure highly transparent driver payouts and automate payment calculation based on shift adherence</li>
                                <li></li>
                            </ol>
                        </div>


                    </div>

                </div>

                <div className="driver-info-section">
                    <div className="driver-info-main-container">

                        <div className="driver-info-container">
                            <p className="app-title">
                                Cash and Payment

                                <br />

                                Management
                            </p>
                            <ol className="driver-info-list1">
                                <li>Manage payments seamlessly by accepting card or online payments instead of cash for COD shipments</li>
                                <li>Gain greater viability of cash reconciliation from drivers at hubs post-delivery</li>
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile4.png"
                                className="image-container13"
                            />
                        </div>


                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile5.png"
                                className="image-container13"
                            />
                        </div>


                        <div className="driver-info-container">
                            <p className="app-title">
                                Customer Communication
                                and

                                <br />

                                Feedback
                            </p>
                            <ol className="driver-info-list1">
                                <li>​​Whitelabelled customer communications using automated SMS and WhatsApp</li>
                                <li>Enable customers to live track orders/parcels via engaging graphics</li>
                                <li>Allow customers to add alternate contacts, pin location and reschedule deliveries</li>
                            </ol>
                        </div>


                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">

                        <div className="driver-info-container">
                            <p className="app-title">
                                Analytics and


                                <br />

                                Dashboards
                            </p>
                            <ol className="driver-info-list1">
                                <li>Centralized dashboard for monitoring deliveries, aggregating driver analytics, gaing order visibility and other SLAs</li>
                                <li>KPI dashboard with TV mode - for continuous monitoring of hup operations</li>
                                <li>Real-time alerts/exceptions based on predefined benchmarks to identify optimization pockets</li>
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile6.png"
                                className="image-container13"
                            />
                        </div>


                    </div>

                </div>
                <SignUpForm />



            </div>
            <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />
        </div>
    )
}

export default LastMile;
