import { Button } from "@mui/base";
import "./style.css";
import { Link } from "react-router-dom";

function SignUpForm() {
  return (
    <div className="supply-chain-demo-section">
      <div className="demo-container">
        <p className="signup-title">Sign Up For A Customized Demo</p>
        <p className="signup-description">
          Tell us a little about yourself and our in-house supply chain experts will help you address your business&apos; unique challenges through a customized demo.
        </p>
      </div>
      <Link to="/LetsTalk">
        <Button className="signup-button">Request Demo</Button>

      </Link>
      {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

    </div>
  );
}

export default SignUpForm;
