import OsDescription from '../OsDescription'
import RenderImageCards from "../RenderImageCards/RenderImageCards";
import "./style.css";
import React from 'react';

function WhistleOsOverview({ possibleImageCards }: any) {
  return (
    <div className="os-benefits-container">
      <div className='os-content-container'>
        <p className="headline">The Logistics OS for Businesses across domains </p>
        <div className="industry-section">
          <div className="tech-platform-container1">
            <img
              src="/assets/home2.png"
              className="image-container1"
            />
          </div>
          <div className="tech-platform-container">
            <OsDescription />
            <RenderImageCards possibleImageCards={possibleImageCards} />
          </div>
        </div>

      </div>

    </div>
  );
}

export default WhistleOsOverview;
