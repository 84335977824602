import { Input, Button } from "@mui/base";
import ServiceCompanySection from "../ServiceCompanySection/ServiceCompanySection";
import RenderPageTitles from "../RenderPageTitles/RenderPageTitles";
import CopyrightSection from "../CopyrightSection/CopyrightSection";
import "./style.css";

function PageContent({ possiblePageTitles }: any) {
  return (
    <div className="page-content-main-container">
      <div className="main-container1">
        <p className="platform-title">
          <span className="whistle-os-title2">Whistle</span>
          <span className="whistle-os-title1">OS</span>
        </p>
        <div className="service-section">
          <ServiceCompanySection possiblePageTitles={possiblePageTitles} />
          <div className="subscription-container">
            {/* <p className="subscribe-title">Subscribe to updates </p>
           
            <Input slotProps={{ root: { className: "subscribe-container" }, input: { className: "subscription-input subscription-input::placeholder", placeholder: "Work Mail*", type: "text" } }} /> */}
            <div className="subscription-section">

              <div className="form-container1">
                {/* <p className="outfit-text">
                  <span>By filling this form, you agree to our </span>
                  <span className="privacy-policy-link">Privacy Policy</span>
                </p>
                
                <Button className="subscribe-button">Subscribe Now</Button> */}
                <div className="policy-links-container">
                  <a href="https://www.whistledrive.com/privacy-policy" className="outfit-text">Privacy Policy</a>
                  <a href="https://www.whistledrive.com/quality-policy" className="outfit-text">Quality Policy</a>
                  <a href="https://www.whistledrive.com/terms-and-conditions" className="outfit-text">Terms&Conditions</a>
                </div>
                <div className="content-wrapper">
                  <a href="https://www.facebook.com/whistledrive/"><img
                    src="/assets/facebook 1.png"
                    className="image-container6"
                  /></a>
                  <a href="https://www.linkedin.com/company/whistledrive/">
                    <img
                      src="/assets/linkedin 1.png"
                      className="image-container6"
                    />
                  </a>

                  <a href="https://twitter.com/WhistleDrive/">
                    <img
                      src="/assets/twitter-alt-circle 1.png"
                      className="image-container6"
                    />
                  </a>
                  <a href="https://www.youtube.com/@gowhistledrive">
                    <img
                      src="/assets/youtube 1.png"
                      className="image-container6"
                    />

                  </a>


                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="privacy-info">


        </div>
      </div>
      <CopyrightSection />
    </div>
  );
}

export default PageContent;
