import "./style.css";

function OsDescription() {
  return (
    <div className="tech-platform-container3">
      <p className="os-title">
        One OS.
        <br />
        Many Industries
      </p>
      <p className="tech-platform-description">
        Built to Scale. Our tech platform can be put to use for
        {/* <br /> */}
        transportation use-cases across a variety of businesses,
        {/* <br /> */}
        no matter the size.
      </p>
    </div>
  );
}

export default OsDescription;
