import ImageCard from "../ImageCard/ImageCard";
import "./style.css";

function RenderDeliveryCards({ possibleImageCards }:any) {
  return (
    <div className="industry-container">
      {possibleImageCards.map((data:any, index:any) => {
        return <ImageCard {...data} key={index} />;
      })}
    </div>
  );
}

export default RenderDeliveryCards;
