import "./style.css";

function ImageCard({ imgContent, industryType }:any) {
  return (
    <div className="industry-type-container2">
      
        <img className="image-container-svg" src={imgContent} />

      <p className="industry-type-label">{industryType}</p>
    </div>
  );
}

export default ImageCard;
