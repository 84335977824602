import "./style.css";

function SmartFeatures({ SmartFeaturesData }: any) {
  return (
    <div className="feature-grid1">
      {/* <div className="feature-grid">
        <div className="container-grid">
          <div className="main-container-api" />
        </div>
        <div className="image-container1">
          <div className="rounded-container1">
            <img
              src="/assets/img_548_530_e18c2d.svg"
              className="image-container8"
            />
          </div>
        </div>
        <div className="image-container2">
          <div className="card-container">
            <img
              src="/assets/img_548_549_ebc37c.svg"
              className="image-container7"
            />
          </div>
        </div>
        <div className="image-container2">
          <div className="rounded-container1">
            <img
              src="/assets/img_548_555_761ffc.svg"
              className="image-container7"
            />
          </div>
        </div>
        <div className="image-container">
          <div className="rounded-container">
            <img
              src="/assets/img_548_560_e11fa4.svg"
              className="image-container6"
            />
          </div>
        </div>
      </div> */}
      <div className="feature-list">
        <div className="smart-apis-container">
          <img src={`./assets/${SmartFeaturesData[0].img}.png`} className="icon" />
          <p className="smart-apis-title">{SmartFeaturesData[0].title3}</p>
          <p className="smart-apis-description">{SmartFeaturesData[0].desc}</p>
        </div>
        <hr style={{ backgroundColor: 'black', height: '2px', width: '20%', alignSelf: 'center'}} />
        <div className="smart-apis-container">
          <img src={`./assets/${SmartFeaturesData[1].img}.png`} className="icon" />
          <p className="intelligent-load-planning">
            {SmartFeaturesData[1].title3}
          </p>
          <p className="intelligent-load-planning1">{SmartFeaturesData[1].desc}</p>
        </div>
        <hr style={{ backgroundColor: 'black', height: '2px', width: '20%', alignSelf: 'center' }} />
        <div className="smart-apis-container">
          <img src={`./assets/${SmartFeaturesData[2].img}.png`} className="icon" />
          <p className="intelligent-load-planning">
            {SmartFeaturesData[2].title3}
          </p>
          <p className="reach-description">{SmartFeaturesData[2].desc}</p>
        </div>
        <hr style={{ backgroundColor: 'black', height: '2px', width: '20%', alignSelf: 'center' }} />
        <div className="smart-apis-container">
          <img src={`./assets/${SmartFeaturesData[3].img}.png`} className="icon" />
          <p className="informed-customers">
            {SmartFeaturesData[3].title3}
          </p>
          <p className="customer-notification">{SmartFeaturesData[3].desc}</p>
        </div>
      </div>
    </div>
  );
}

export default SmartFeatures;
