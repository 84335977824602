import FormInputs from "../FormInputs/FormInputs";
import "./style.css";

function BookMeetingForm() {
  return (
    <div className="meeting-booking-container">
      <p className="book-meeting-title">Book a meeting</p>
      <FormInputs />
    </div>
  );
}

export default BookMeetingForm;
