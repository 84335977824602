import DeliveryExperience from "../DeliveryExperience";
// import SmartFeatures from "../SmartFeatures";
import CustomerCommunication from "../CustomerCommunication";
// import SignUpForm from "../SignUpForm";
// import ImageContainer from "../ImageContainer";
import "./style.css";
import SignUpForm from "../SignUpForm";
import SmartFeatures from "../SmartFeatures";
import ImageContainer from "../ImageContainer";
import WebsiteLayout from "../WebsiteLayout/WebsiteLayout";
const SmartFeaturesData = [
  {
    id: 1,
    mainTitle: "Ensure Seamless Integrations with Disparate",
    title2: "Logistics Systems",
    title3: "System Interoperability",
    desc: "Seamless connection to ERP/Shipsy route planning and optimization system",
    img: 'lasticon1'

  },
  {
    id: 2,
    mainTitle: "Ensure Seamless Integrations with Disparate",
    title2: "Logistics Systems",
    title3: "Faster Deliveries",
    desc: "Zone/geofence-based driver allocation to leverage local knowledge about routes and make delivery faster",
    img: 'lasticon2'

  },
  {
    id: 3,
    mainTitle: "Ensure Seamless Integrations with Disparate",
    title2: "Logistics Systems",
    title3: "Multi-Screen Experience",
    desc: "Shipsy Field Ops - iOS and Android app with easy UI helps capturing delivery indicators",
    img: 'lasticon3'

  },
  {
    id: 4,
    mainTitle: "Ensure Seamless Integrations with Disparate",
    title2: "Logistics Systems",
    title3: "Seamless Customer Intimations",
    desc: "Unified, real-time customer communication - SMS/WhatsApp/Email",
    img: 'lasticon4'


  },
]

function DeliveryExperienceSection() {
  return (
    <div className="delivery-experience-section4">
      <DeliveryExperience />
      <div className="delivery-operations">
        <p className="delivery-heading">
          Seamless Last Mile Delivery Operations for
          <br />
          On-Time &amp; Delightful Experiences
        </p>
        <SmartFeatures SmartFeaturesData={SmartFeaturesData} />
      </div>
      <div className="driver-info-section">
        <div className="driver-info-main-container">
          <div className="driver-info-container">
            <p className="app-title">
              Field Ops Application
              <br />
              for Drivers
            </p>
            <ol className="driver-info-list">
              <li>Completely configurable app flow to capture various kinds of driver information</li>
              <li>Execute zero-contact delivery through a one-time password sent to the recipient</li>
              <li>Configurable screens for automatically capturing photos/ KYC etc.</li>
              <li>Accept card or online payments instead of cash for COD shipments.</li>
              <li>Driver cash reconciliation at hub post-delivery</li>
            </ol>
          </div>
          <div className="driver-info-image">
            <img
              src="./assets/ontime2.png"
              className="image-container13"
            />
          </div>

        </div>

      </div>
      <div className="driver-info-section">
        <div className="driver-info-main-container">
          <div className="driver-info-image">
            <img
              src="./assets/ontime3.png"
              className="image-container13"
            />
          </div>
          <div className="driver-info-container">
            <p className="app-title">
              Real-Time Dashboard
              <br />
              for Hub Ops
            </p>
            <ol className="driver-info-list">
              <li>Single dashboard for hub managers to track last mile performance in real-time</li>
              <li>KPI dashboard with TV mode - for continuous monitoring of Hub operations</li>
              <li>Real-time alerts/exceptions based on predefined benchmarks to identify optimization pockets</li>
              <li>Gamification of incentives to boost driver engagement and productivity</li>
            </ol>
          </div>


        </div>

      </div>
      {/* <div className="hub-operations-main-container">
        <div className="hub-operations-dashboard">
          <div className="hub-ops-dashboard2">
            <img
             
              className="image-container9"
            />
          </div>
          <div className="hub-ops-dashboard">
            <p className="hub-ops-dashboard-title">
              
            </p>
            <ol className="hub-ops-dashboard1">
             
            </ol>
          </div>
        </div>
      </div> */}
      <div className="driver-info-section">
        <div className="driver-info-main-container">

          <div className="driver-info-container">
            <p className="app-title">
              Generate Unified
              <br />
              Customer Communication
            </p>
            <ol className="driver-info-list">
              <li>Whitelabelled customer communications sent out through SMS and WhatsApp</li>
              <li>Enable customers to live track orders/parcels via engaging graphics</li>
              <li>Allow customers to add alternate contacts, pin location and reschedule deliveries</li>
              <li>Quickly validate non-delivery claims made by drivers with consignee</li>
            </ol>
          </div>
          <div className="driver-info-image">
            <img
              src="./assets/ontime4.png"
              className="image-container13"
            />
          </div>


        </div>

      </div>
      <SignUpForm />


    </div>
  );
}

export default DeliveryExperienceSection;
