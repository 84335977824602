import { Button } from "@mui/base";
import "./style.css";
import { Link } from "react-router-dom";

function DeliveryExperience() {
  return (
    <div className="delivery-experience-section6">
      <div className="delivery-section">
        <div className="delivery-info">
          <p className="enhanced-customer-experience-heading">
            On-Time Deliveries for Enhanced Customer  Experience
            {/* <br /> */}
           
            {/* <br /> */}
           
          </p>
          <p className="enhanced-customer-experience">
            Ensure timely and accurate deliveries with real-time updates
            <br />
            to your customers. Deliver a seamless experience to your
            <br />
            drivers and delivery partners at the same time.
          </p>
          <Link to='/LetsTalk'>
            <Button className="enhanced-customer-experience-button">Request a demo</Button>

          </Link>

        </div>
        <div className="delivery-image">
          <img
            src="../assets/ontime1.png"
            className="image-container5"
          />
        </div>
      </div>
      {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

    </div>
  );
}

export default DeliveryExperience;
