import { Input, Button } from "@mui/base";
import "./style.css";

import { useFormik } from 'formik';
import * as yup from "yup";
// import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { useState } from "react";

const initialValues = {
  name: '',
  lastname: '',
  website: '',
  mobile: '',
  email: '',
  message: ''
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  lastname: yup.string().required(),
  website: yup.string(),
  mobile: yup
    .string()
    .required()
    // .matches(/[1-9][0-9]{9}/, "Please enter valid mobile number")
    .min(10),
  email: yup.string().email().required("Email is Required"),
  message: yup.string().required()

})


function FormInputs() {
  const [success, setSuccess] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState<any>(false);


  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    // resetForm,
    setFieldValue

  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values)
      var subject = "IMP: New Enquiry on Website";
      var apiKey = "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";

      var data = {
        sender: { email: values.email },
        to: [{ email: "vinay.yarabolu@whistledrive.com" }, { email: "kalyan.utti@whistledrive.com" }, { email: "hello@whistledrive.com" }],
        // cc: [{email: 'uttikalyan123@gmail.com'}, {email: 'bharshavardhini16@gmail.com'}],
        subject: subject,
        templateId: 1,
        params: {
          name: values.name,
          email: values.email,
          phone: values.mobile,
          message: values.message
        }
      };
      const config: any = {
        method: "POST",
        url: 'https://api.sendinblue.com/v3/smtp/email',
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey
        },
        data: JSON.stringify(data)

      }

      axios(config)
        .then((res) => {
          if (res.status === 201) {
            console.log("Email sent successfully!");
            setSuccess(true)

            var contactData = {
              email: values.email,
              listIds: [4],
              attributes: {
                SOURCE: "ContactUs",
              }
            };

            const config1: any = {
              method: "POST",
              url: "https://api.brevo.com/v3/contacts",
              headers: {
                "Content-Type": "application/json",
                "api-key": apiKey
              },
              data: JSON.stringify(contactData)
            }

            axios(config1)
              .then((response) => {
                console.log("Contact list saved")
              })
              .catch((err) => {
                console.log("Error occurred while adding recipient email to contact list:", err);
              });
          }
        })
        .catch(function (error) {
          // console.log("Error occurred while sending email:", error);
          alert("Something went wrong")
          setErrorMsg(true)
        });

      resetForm()
    }
  })
  return (
    <div className="privacy-policy-container">
      <div className="form-container">
        {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
        <Input
          slotProps={{ root: { className: "rounded-input" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "First Name*", type: "text", onChange: handleChange('name'), onBlur: handleBlur('name'), value: values.name } }} />

        {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
        <Input slotProps={{ root: { className: "rounded-input2" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "Last Name*", type: "text",onChange: handleChange('lastname'), onBlur: handleBlur('lastname'), value: values.lastname } }} />
      </div>
      {touched.name && errors.name ? (
        <p className="errorMsg">Name is required</p>
      ) : null}
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input
        slotProps={{
          root: { className: "rounded-input1" },
          input:
          {
            className: "transparent-input transparent-input::placeholder",
            placeholder: "Phone Number*",
            type: "text",
            maxLength: 10,
            onChange: (text: any) => {
              const regex =
                /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
              const textValue = text.target.value;

              if (regex.test(textValue.toString()) || textValue === "") {
                setFieldValue("mobile", textValue);
              }
              console.log(textValue);
              console.log(values.mobile);
            },
            onBlur: handleBlur('mobile'),
            value: values.mobile,

          }
        }}
      />
      {touched.mobile && errors.mobile ? (
        <p className="errorMsg">Mobile Number is required</p>
      ) : null}
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input slotProps={{ root: { className: "rounded-input1" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "Email*", type: "email", onChange: handleChange('email'), onBlur: handleBlur('email'), value: values.email } }} />
      {touched.email && errors.email ? (
        <p className="errorMsg">Email is required</p>
      ) : null}
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input slotProps={{ root: { className: "rounded-input1" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "Website", type: "text", onChange: handleChange('website'), onBlur: handleBlur('website'), value: values.website } }} />
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      <Input slotProps={{ root: { className: "rounded-input1" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "Message*", type: "text", onChange: handleChange('message'), onBlur: handleBlur('message'), value: values.message } }} />
      {touched.message && errors.message ? (
        <p className="errorMsg">Message is required</p>
      ) : null}
      {/* Input Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      {/* <Input slotProps={{ root: { className: "rounded-input1" }, input: { className: "transparent-input transparent-input::placeholder", placeholder: "Last Name*", type: "text" } }} /> */}
      <p className="privacy-policy-text">By filling this form, you agree to our Privacy Policy</p>
      {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}
      {success ? <p style={{ color: "green" }}>Thanks for Interest</p> : ""}
      {errorMsg ? <p style={{ color: 'red' }}>Something went wrong</p> : ""}
      <p className="meeting-button" onClick={() => handleSubmit()}>Book a meeting</p>
    </div>
  );
}

export default FormInputs;
