import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import DeliverySectionLayout from '../../Components/DeliverySectionLayout/DeliverSectionLayout';
import './styles.css'
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';
import { Button } from '@mui/base';
import SmartFeatures from '../../Components/SmartFeatures';
import SignUpForm from '../../Components/SignUpForm';
import CustomerCommunication from '../../Components/CustomerCommunication';
import { Link } from 'react-router-dom';

const mockData = {
    possibleImageCards: [
        {
            industryType: "eCommerce",
            imgContent:
                "/assets/img_73_64_d65bb1.svg",
        },
        {
            industryType: "QCommerce",
            imgContent:
                "/assets/img_73_67_eec6ca.svg",
        },
        {
            industryType: "D2C",
            imgContent:
                "/assets/img_73_70_192a70.svg",
        },
        {
            industryType: "3PLs",
            imgContent:
                "/assets/img_73_73_e92f31.svg",
        },
        {
            industryType: "FMCG & Retail",
            imgContent:
                "/assets/img_73_76_b933f2.svg",
        },
        {
            industryType: "Commodities",
            imgContent:
                "/assets/img_73_79_8938b3.svg",
        },
    ],
    possiblePageTitles: [{ pageTitle: "WhistleOS for Mobility" }, { pageTitle: "Executive Car Rentals" }, { pageTitle: "WhistleOS for Transportation" }, { pageTitle: "Whistle Electric" }],
};

const osAdvantages = [
    {
        id: 1,
        image: 'low-code',
        title: 'Low-Code Integrations'
    },
    {
        id: 2,
        image: 'third-party',
        title: 'Third-Party Connectors'
    },
    {
        id: 3,
        image: 'costs',
        title: 'No Upfront Costs'
    },
    {
        id: 4,
        image: 'time',
        title: 'Quick Time to '
    },
    {
        id: 5,
        image: 'future',
        title: 'Future Ready Platform'
    },
    {
        id: 6,
        image: 'approach',
        title: 'Consultative Approach'
    },
]

const SmartFeaturesData = [
    {
        id: 1,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Smart APIs",
        desc: "Effortlessly synchronize order information directly from your WMS/OMS/ERP systems with our straightforward API interfaces. ",
        img: 'smart-api'

    },
    {
        id: 2,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Intelligent Load Planning",
        desc: " Leverage precision in load planning by incorporating constraints and needs assessment to streamline your operations. ",
        img: 'Load-planning'

    },
    {
        id: 3,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Expand Reach",
        desc: "Achieve comprehensive operational integration with over 50 third-party logistics (3PL) partners for expanded logistics capabilities. ",
        img: 'reach'

    },
    {
        id: 4,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Informed Customers",
        desc: "Keep your customers proactively updated with automated, real-time notifications across emails, SMS, WhatsApp, and more. ",
        img: 'customers'


    },
]

function RouteOptimization() {
    return (
        <div className='main-container'>
            <Navbar />
            {/* <DeliverySectionLayout possiblePageTitles={mockData?.possiblePageTitles} /> */}
            <div className="delivery-experience-section4">
                {/* <DeliveryExperience /> */}
                <div className="delivery-experience-section6">
                    <div className="delivery-section">
                        <div className="delivery-info">
                            <p className="enhanced-customer-experience-heading">
                                Intelligent Delivery
                                <br />
                                Planning To Boost
                                <br />
                                Customer Experience
                            </p>

                            <p className="enhanced-customer-experience">
                                Effectively manage fleets for efficient first and last-mile delivery.

                                Ensure on-time deliveries, optimize capacity utilization and drive intelligent planning.
                            </p>
                            <Link to="/LetsTalk">
                                <Button className="enhanced-customer-experience-button">Request a demo</Button>

                            </Link>

                        </div>
                        <div className="delivery-image">
                            <img
                                src="./assets/route1.png"
                                className="image-container5"
                            />
                        </div>
                    </div>
                    {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

                </div>
                <div className="delivery-operations">
                    <p className="delivery-heading">
                    Integrate Seamlessly, Deliver Exceptionall

                       
                    </p>
                    <SmartFeatures SmartFeaturesData={SmartFeaturesData} />
                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-container">
                            <p className="app-title">
                                Streamlined Fleet

                                <br />
                                Capacity Management
                            </p>
                            <ol className="driver-info-list1">
                                <li>Effortless Vehicle Data Sync: Directly integrate vehicle information from your ERP for hassle-free management.</li>
                                <li>Detailed Consignment Integration: Embed delivery details from your ERP to fine-tune load planning. </li>
                                <li>Optimized Load Efficiency: Utilize intelligent algorithms for enhanced vehicle capacity utilization. </li>
                                <li>Increased Delivery Productivity: Boost the number of deliveries per vehicle to save on costs and improve productivity. </li>
                                {/* <li>Driver cash reconciliation at hub post-delivery</li> */}
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/Route2.png"
                                className="image-container13"
                            />
                        </div>

                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/home3.png"
                                className="image-container13"
                            />
                        </div>
                        <div className="driver-info-container">
                            <p className="app-title">
                                Smart Route Optimization
                                <br />
                                for Peak Performance
                            </p>
                            <ol className="driver-info-list1">
                                <li>Harness sophisticated algorithms for smart consignment allocation. </li>
                                <li>Identify the most cost-effective routes for every delivery.</li>
                                <li>Reduce operational expenses and enhance fleet productivity with optimized routing. </li>

                            </ol>
                        </div>


                    </div>

                </div>

                <div className="driver-info-section">
                    <div className="driver-info-main-container">

                        <div className="driver-info-container">
                            <p className="app-title">
                                Enhance Last-Mile

                                <br />
                                Delivery Efficiency
                            </p>
                            <ol className="driver-info-list1">
                                <li>Streamline driver assignments and trip planning with our automated system.</li>
                                <li>Consolidate multiple deliveries into a single journey for greater efficiency on our platform. </li>
                                <li>Monitor delivery progress in real-time to refine performance metrics. </li>
                                <li>Implement digital proof of delivery to ensure end-to-end visibility. </li>
                                {/* <li>Minimize leasing, fuel costs, and delivery time by increasing fleet capacity and efficient route planning</li> */}
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/lastmile.png"
                                className="image-container13"

                            />
                        </div>


                    </div>

                </div>
                <div className="driver-info-section" style={{paddingBottom:"5%"}}>
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/route3.png"
                                className="phoneimage"
                            />
                        </div>
                        <div className="driver-info-container">
                            <p className="app-title">
                                Real-time Tracking f
                                <br />
                                or Informed Customers
                            </p>
                            <ol className="driver-info-list1">
                                <li>Offer customers an immediate way to monitor their delivery with a simple tracking link, providing precise estimated times of arrival. </li>
                                <li>Arrange deliveries to meet customer schedules by efficiently organizing consignments for optimal loading and delivery sequencing. </li>
                                <li>Enable customers to provide instant feedback on their delivery experience through a user-friendly mobile app.</li>

                            </ol>
                        </div>


                    </div>

                </div>
                
                <div className='whistle-os-main-container'>
                    <div className='whistle-os-cont'>
                        <p className="whistle-os-title">The WhistleOS Advantage</p>
                        <div className='card-container-os' >
                            {osAdvantages?.map((eachItem) => {
                                return (

                                    <div className='card-content' key={eachItem?.id}>
                                        <img src={`./assets/${eachItem?.image}.png`} className='card-img' />
                                        <p className='card-desc2'>{eachItem?.title}</p>
                                    </div>


                                )
                            })}
                        </div>


                    </div>
                </div>
                <SignUpForm />


            </div>
            <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />
        </div>
    )
}

export default RouteOptimization;
