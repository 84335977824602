import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'

import { Button } from '@mui/base';
import './styles.css';
import WhistleOsOverview from '../../Components/WhistleOsOverview/WhistleOsOverview'
import DispatchPlatform from '../../Components/DispatchPlatform/DispatchPlatform';
import RenderDeliveryCards from '../../Components/RenderDeliveryCards/RenderDeliveryCards';
import CustomerFeedbackSection from '../../Components/CustomerFeedbackSection/CustomerFeedbackSection';
import BookMeetingForm from '../../Components/BookMeetingForm/BookMeetingForm';
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';






function LetsTalk() {

    const [active, setActive] = useState<any>("Fulfillment");
    const [contentdata, setContentData] = useState<any>({
        name: 'Fulfillment',
        title: 'Transform How You Fulfill Orders and Win Customer Trust ',
        desc1: 'Elevate the customer experience with our Fulfillment Automation solutions. We give you the power to tailor delivery schedules, choose precise locations, and adjust order details on the fly—factors that are crucial for maximizing fulfilment efficiency.',
        desc2: `Double the impact of your delivery strategy with WhistleOS. With our system, you're not just sending parcels; you're delivering customer satisfaction.`
    })

  




    const mockData = {
        possibleImageCards: [
            {
                industryType: "eCommerce",
                imgContent:
                    "/assets/img_73_64_d65bb1.svg",
            },
            {
                industryType: "QCommerce",
                imgContent:
                    "/assets/img_73_67_eec6ca.svg",
            },
            {
                industryType: "D2C",
                imgContent:
                    "/assets/img_73_70_192a70.svg",
            },
            {
                industryType: "3PLs",
                imgContent:
                    "/assets/img_73_73_e92f31.svg",
            },
            {
                industryType: "FMCG & Retail",
                imgContent:
                    "/assets/img_73_76_b933f2.svg",
            },
            {
                industryType: "Commodities",
                imgContent:
                    "/assets/img_73_79_8938b3.svg",
            },
        ],
        possiblePageTitles: [{ pageTitle: "Platform" }, { pageTitle: "First Mile" }, { pageTitle: "Middle Mile" }, { pageTitle: "Last Mile" }],
    };

    const content = [{
        name: 'Fulfillment',
        title: 'Transform How You Fulfill Orders and Win Customer Trust ',
        desc1: 'Elevate the customer experience with our Fulfillment Automation solutions. We give you the power to tailor delivery schedules, choose precise locations, and adjust order details on the fly—factors that are crucial for maximizing fulfilment efficiency.',
        desc2: `Double the impact of your delivery strategy with WhistleOS. With our system, you're not just sending parcels; you're delivering customer satisfaction.`
    },
    {
        name: 'Delivery',
        title: 'Streamline Your Shipping Workflow',
        desc1: 'Efficient delivery is the backbone of a successful logistics strategy. Our platform ensures your products reach their destination swiftly and securely. With real-time routing and automated dispatch, WhistleOS turns complex delivery logistics into a seamless operation, allowing for prompt and reliable shipments every time.',
        desc2: ``
    },
    {
        name: 'Capacity',
        title: 'Optimize Your Load Management',
        desc1: 'Maximize your logistics efficiency with WhistleOS. Our capacity planning tools help you understand and manage your load capabilities better, ensuring you utilize every inch of space smartly. Say goodbye to underutilized space and hello to cost-effective shipping and handling. ',
        desc2: ``
    },
    {
        name: 'Track',
        title: 'Real-Time Visibility, Every Step of the Way',
        desc1: 'Never lose sight of your deliveries. Our real-time tracking module keeps you in the loop from departure to arrival. With WhistleOS, empower your customers with the ability to monitor their orders in real-time, providing transparency and building trust.',
        desc2: ``
    },
    {
        name: 'Analytics',
        title: 'Data-Driven Decisions at Your Fingertips',
        desc1: 'Turn data into action with WhistleOS analytics. Our platform analyzes trends, monitors performance, and generates actionable insights, giving you the power to make informed decisions. Use our analytics to refine your processes, reduce costs, and improve service delivery.',
        desc2: ``
    },
    ]

    const handleFullFillment = (activeValue:any) => {
        setActive(activeValue)
        content.map((eachItem)=>{
            if(eachItem.name===activeValue){
                setContentData(eachItem)
            }
        })

    }



    return (
        <div className='main-container'>
            <Navbar />
            <div className='delivery-main-container'>
                <div className="delivery-experience-section2">
                    <div className="delivery-experience-section1">
                        <p className="delivery-experience-heading">
                            Transform your
                            <br />
                            delivery experience
                        </p>
                        {/* <p className="delivery-experience-text">
                            Speak to one of our last-mile delivery experts and start your journey.
                            <br />
                            Our team of specialists will walk through the challenges your business is
                            <br />
                            facing, discuss industry best-practice and how FayEye can help
                            <br />
                            to transform your logistics operation.
                        </p> */}
                    </div>
                    <div className="delivery-expert-section" id="contact-us">
                        <BookMeetingForm />
                    </div>
                </div>

            </div>

            <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />




        </div>
    )
}

export default LetsTalk;
