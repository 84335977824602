import DeliveryExperienceSection from "../DeliveryExperienceSection/DeliveryExperienceSection";
import WebsiteLayout from "../WebsiteLayout/WebsiteLayout";
// import PageLayout from "../PageLayout";
import "./style.css";

function DeliverySectionLayout({ pageTitles }:any) {
  return (
    <div className="main-container4">
      <DeliveryExperienceSection />
      {/* <WebsiteLayout possiblePageTitles={pageTitles} /> */}
      {/* <PageLayout pageTitles={pageTitles} /> */}
    </div>
  );
}

export default DeliverySectionLayout;
