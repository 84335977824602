import { Link } from "react-router-dom";
import RenderPageTitles from "../RenderPageTitles/RenderPageTitles";
import TransportationOptions from "../TransportationOptions/TransportationOptions";
import "./style.css";

function ServiceCompanySection({ possiblePageTitles }: any) {
  return (
    <div className="industry-type-container">
      <div className="services-container">
        <p className="service-title">Solutions</p>
        {/* <TransportationOptions /> */}
        {/* <RenderPageTitles possiblePageTitles={possiblePageTitles} /> */}
        <div className="page-title-container">
          {/* <Link to="/Platform" className="page-title page-title1">Platform</Link>;<br /> */}
          <Link to="/first-mile" className="page-title page-title1">First Mile</Link><br />
          <Link to="/mid-mile" className="page-title page-title1">Mid Mile</Link><br />
          <Link to="/last-mile" className="page-title page-title1">Last Mile</Link>

        </div>
      </div>
      <div className="company-info">
        <Link to="/platform" className="page-title page-title1">Platform</Link>
        <div className="transportation-section">
          <Link to="/on-demand-delivery-management" className="about-us-text">On Demand Delivery Management</Link>
          {/* <p className="employee-transportation">News Room</p> */}
        </div>
      </div>
    </div>
  );
}

export default ServiceCompanySection;
