import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import DeliverySectionLayout from '../../Components/DeliverySectionLayout/DeliverSectionLayout';
import './styles.css'
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';

const mockData = {
  possibleImageCards: [
    {
      industryType: "eCommerce",
      imgContent:
        "/assets/img_73_64_d65bb1.svg",
    },
    {
      industryType: "QCommerce",
      imgContent:
        "/assets/img_73_67_eec6ca.svg",
    },
    {
      industryType: "D2C",
      imgContent:
        "/assets/img_73_70_192a70.svg",
    },
    {
      industryType: "3PLs",
      imgContent:
        "/assets/img_73_73_e92f31.svg",
    },
    {
      industryType: "FMCG & Retail",
      imgContent:
        "/assets/img_73_76_b933f2.svg",
    },
    {
      industryType: "Commodities",
      imgContent:
        "/assets/img_73_79_8938b3.svg",
    },
  ],
  possiblePageTitles: [{ pageTitle: "WhistleOS for Mobility" }, { pageTitle: "Executive Car Rentals" }, { pageTitle: "WhistleOS for Transportation" }, { pageTitle: "Whistle Electric" }],
};
function OnTime() {
  return (
    <div className='main-container'>
      <Navbar />
      <DeliverySectionLayout possiblePageTitles={mockData?.possiblePageTitles} />
      <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />
    </div>
  )
}

export default OnTime;
