import "./style.css";

function CopyrightSection() {
  return (
    <div className="footer-container">
      <p className="outfit-text">copyright © WhistleDrive 2023</p>
    </div>
  );
}

export default CopyrightSection;
