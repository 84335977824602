import React from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import DeliverySectionLayout from '../../Components/DeliverySectionLayout/DeliverSectionLayout';
import './styles.css'
import WebsiteLayout from '../../Components/WebsiteLayout/WebsiteLayout';
import { Button } from '@mui/base';
import SmartFeatures from '../../Components/SmartFeatures';
import SignUpForm from '../../Components/SignUpForm';
import CustomerCommunication from '../../Components/CustomerCommunication';
import { Link } from 'react-router-dom';

const mockData = {
    possibleImageCards: [
        {
            industryType: "eCommerce",
            imgContent:
                "/assets/img_73_64_d65bb1.svg",
        },
        {
            industryType: "QCommerce",
            imgContent:
                "/assets/img_73_67_eec6ca.svg",
        },
        {
            industryType: "D2C",
            imgContent:
                "/assets/img_73_70_192a70.svg",
        },
        {
            industryType: "3PLs",
            imgContent:
                "/assets/img_73_73_e92f31.svg",
        },
        {
            industryType: "FMCG & Retail",
            imgContent:
                "/assets/img_73_76_b933f2.svg",
        },
        {
            industryType: "Commodities",
            imgContent:
                "/assets/img_73_79_8938b3.svg",
        },
    ],
    possiblePageTitles: [{ pageTitle: "WhistleOS for Mobility" }, { pageTitle: "Executive Car Rentals" }, { pageTitle: "WhistleOS for Transportation" }, { pageTitle: "Whistle Electric" }],
};

const osAdvantages = [
    {
        id: 1,
        image: 'low-code',
        title: 'Low-Code Integrations'
    },
    {
        id: 2,
        image: 'third-party',
        title: 'Third-Party Connectors'
    },
    {
        id: 3,
        image: 'costs',
        title: 'No Upfront Costs'
    },
    {
        id: 4,
        image: 'time',
        title: 'Quick Time to '
    },
    {
        id: 5,
        image: 'future',
        title: 'Future Ready Platform'
    },
    {
        id: 6,
        image: 'approach',
        title: 'Consultative Approach'
    },
]

const SmartFeaturesData = [
    {
        id: 1,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Easy Integration",
        desc: "APIs / Customer Portal / App for retail customers.",
        img: 'firstmileicon1'

    },
    {
        id: 2,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Smart Allocation Rules",
        desc: "Automate allocation of orders to hub and drivers",
        img: 'firstmileicon2'

    },
    {
        id: 3,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "WhistleOS Field Ops",
        desc: "iOS and Android mobile app to enable scan-based pick-ups by drivers at client’s location.",
        img: 'firstmileicon3'

    },
    {
        id: 4,
        mainTitle: "Ensure Seamless Integrations with Disparate",
        title2: "Logistics Systems",
        title3: "Route Planning and Optimization",
        desc: "Combine pickups & deliveries on the go for efficient orchestration.",
        img: 'firstmileicon4'


    },
]

function FirstMile() {
    return (
        <div className='main-container'>
            <Navbar />
            {/* <DeliverySectionLayout possiblePageTitles={mockData?.possiblePageTitles} /> */}
            <div className="delivery-experience-section4">
                {/* <DeliveryExperience /> */}
                <div className="delivery-experience-section6">
                    <div className="delivery-section">
                        <div className="delivery-info">
                            <p className="enhanced-customer-experience-heading">
                                Facilitate Well
                                <br />
                                -Coordinated First-Mile Pick-Ups
                            </p>

                            <p className="enhanced-customer-experience">
                                Maximize efficiency from the start with our platform that ensures no business opportunity is missed. Align internal operations with key performance indicators and cater to a broad spectrum of customers.
                            </p>
                            <Link to="/LetsTalk">
                                <Button className="enhanced-customer-experience-button">Request a demo</Button>

                            </Link>

                        </div>
                        <div className="delivery-image">
                            <img
                                src="./assets/firstmile1.png"
                                className="image-container5"
                            />
                        </div>
                    </div>
                    {/* Button Component starts here. We've generated code using MUI Base. See other options in "Component library" dropdown in Settings */}

                </div>
                <div className="delivery-operations">
                    <p className="delivery-heading">
                        Seamless First Mile Operations for Driving

                        <br />

                        Business Growth
                    </p>
                    <SmartFeatures SmartFeaturesData={SmartFeaturesData} />
                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-container">
                            <p className="app-title">
                                Guarantee Smooth Operations

                                <br />
                                with Advanced Tech
                            </p>
                            <ol className="driver-info-list1">
                                <li>User-Friendly Dashboard: Manage operations with a dashboard that offers full visibility and control. </li>
                                <li>Automated Workflows: Simplify complex processes with automation that saves time and reduces errors. </li>

                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/firstmile2.png"
                                className="image-container13"
                            />
                        </div>

                    </div>

                </div>
                <div className="driver-info-section">
                    <div className="driver-info-main-container">
                        <div className="driver-info-image">
                            <img
                                src="/assets/firstmile3.png"
                                className="image-container13"
                            />
                        </div>
                        <div className="driver-info-container">
                            <p className="app-title">
                                Harness the Power of
                                <br />
                                Smart Data & Analytics
                            </p>
                            <ol className="driver-info-list1">
                                <li>Real-Time Analytics: Access immediate data on performance, trends, and opportunities. </li>
                                <li>
                                    Custom Reports: Generate tailored reports that help you understand and optimize every aspect of your operations.
                                </li>

                            </ol>
                        </div>


                    </div>

                </div>

                <div className="driver-info-section" style={{paddingBottom:'5%'}}>
                    <div className="driver-info-main-container">

                        <div className="driver-info-container">
                            <p className="app-title">
                                Leverage Smart Data & Analytics

                                {/* <br /> */}
                                
                            </p>
                            <ol className="driver-info-list1">
                                <li>Track key metrics around first-mile pick-ups</li>
                                <li>
                                    Get customized reports for your business use</li>
                            </ol>
                        </div>
                        <div className="driver-info-image">
                            <img
                                src="/assets/firstmile4.png"
                                className="phoneimage"
                            />
                        </div>


                    </div>

                </div>
                <SignUpForm />



            </div>
            <WebsiteLayout possiblePageTitles={mockData?.possiblePageTitles} />
        </div>
    )
}

export default FirstMile;
