import ImageCard from "../ImageCard/ImageCard";
import "./style.css";
import React from 'react';

function RenderImageCards({ possibleImageCards }:any) {
  return (
    <div className="industry-container">
      {possibleImageCards.map((data:any, index:any) => {
        return <ImageCard {...data} key={index} />;
      })}
    </div>
  );
}

export default RenderImageCards;
